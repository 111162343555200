$wash-black: #020d1c;
$wash-orange: #f2ca89;
$wash-blue: #17499f;
$wash-yellow: #f26419;

.react-loading-skeleton {
  height: 30px;
  margin-bottom: 10px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

// .global-overlay {
// }

.global-overlay .global-overlay-container {
  position: fixed;
  background: #88888869;
  height: 100%;
  z-index: 4;
  width: 100%;
  cursor: no-drop;
  top: 0;
  left: 0;
}

.global-overlay .loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid;
  border-color: #17499f #0000;
  animation: l1 1s infinite;
}

@keyframes l1 {
  to {
    transform: rotate(0.5turn);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.demo-banner {
  background: #16499fa1;
  color: white;
  position: absolute;
  width: 220px;
  text-align: center;
  font-size: 18px;
  transform: rotate(325deg);
  left: -31px;
  height: 29px;
  top: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pickup-button {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  height: 56px;
  color: $wash-blue;
  border: none;
  letter-spacing: 0.28px;
  border-radius: 8px;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
}

.phone-input-0 {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  // width: 288px;
  padding: 12px 16px;
  height: 56px;
  display: flex;
  align-items: center;

  span {
    border-right: 1px solid #d9d9d9;
    padding-right: 16px;
    margin-right: 8px;
    cursor: pointer;
  }

  input {
    height: auto;
    border: none;
    width: 100%;
    // border: 1px solid #d9d9d9;
    border-left: 0;
    border-right: 0;
    &:focus-visible {
      outline: none;
    }
  }
}

.error-message-border {
  outline: 1px solid #f26419;
  border-radius: 8px;
}

.error-message-text {
  margin-bottom: 0;
  font-size: 12px !important;
  margin-top: 5px;
  color: #f26419 !important;
}

input,
select {
  height: 56px;
}
input {
  &::placeholder {
    color: #d9d9d9 !important;
  }
}
label {
  margin-bottom: 8px;
  font-weight: 500;
}

button:disabled {
  background: #cfcfcf !important;
  cursor: none;
  border: none;
}

.check-icon::before {
  content: "\f26e"; /* Unicode for the check mark icon in Font Awesome */
  font-family: "bootstrap-icons";
  margin-right: 3px;
  top: 2.5px;
  position: relative;
}

.wash-counter {
  display: flex;
  font-size: 24px;
  align-items: center;
  border-radius: 24px;
  background: #fafafa;
  width: fit-content;
  height: fit-content;
  gap: 40px;

  p {
    margin-bottom: 0 !important;
    font-size: 20px !important;
  }

  .sign {
    height: 50px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 50px;

    &._add {
      background: #ffd59a;
      border: none;
    }
    &._minus {
      background: #fff8ec;
      border: 1px solid #ffe6bc;
    }
  }
}

.loader-wrapper {
  // background: rgba(255, 255, 255, 0.8);
  // opacity: 0.2;
}

.app-spinner {
  color: #17499f !important;
  width: 20px;
  height: 20px;
}

.app-landing {
  p {
    color: $wash-black;
    line-height: normal;
  }

  .schedule-pickup-btn {
    border-radius: 8px;
    background: #fff;
    color: #17499f;
    letter-spacing: 0.32px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
  }

  &_section-one {
    background: url("../imgs/BBackground.svg");
    background-size: cover;

    .navbar-toggler-icon {
      width: 1em;
      height: 1em;
    }

    &_header-container {
      padding-top: 24px;
      align-items: center;

      ul {
        display: flex;
        list-style: none;
        font-size: 14px;
        font-weight: 700;
        column-gap: 24px;
        align-items: center;
        justify-content: center;
        margin: 0;

        a {
          color: $wash-black;
          font-weight: 600;
        }
      }

      .login-section {
        display: flex;
        align-items: center;
        column-gap: 56px;
        justify-content: center;

        a {
          color: #000;
          text-decoration: none;
          font-weight: 600;
          font-size: 14px;
        }

        p {
          margin: 0;
          font-size: 14px;
          font-weight: 700;
        }

        button {
          border-radius: 8px;
          background: $wash-blue;
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          padding: 20px 32px;
          border: none;
        }
      }
    }

    &_body {
      margin-top: 56px;

      ._location {
        &-info {
          display: flex;
          align-items: center;
          border-radius: 24px;
          background: $wash-orange;
          width: fit-content;
          padding: 12px;
          column-gap: 5px;

          i {
            font-size: 11px;
            line-height: normal;
          }

          p {
            margin: 0;
            font-weight: 400;
            font-size: 14px;

            span {
              font-weight: 700;
            }
          }
        }

        &-prompt {
          font-size: 16px;
          font-weight: 500;
          margin-top: 19px;
        }

        &-input-wrapper {
          height: 56px;
          width: 480px;

          &-addon {
            width: 45px;
            background: white;
            display: flex;
            justify-content: center;
          }

          &-inputbox {
            border-left: none;

            &::placeholder {
              color: #d9d9d9;
            }

            &:focus,
            &:active {
              box-shadow: none;
              outline: none;
              border: 1px solid #dee2e6;
              border-left: none;
            }
          }
        }

        &-schedule-button {
          background: $wash-blue;
          color: white;
          font-size: 14px;
          font-weight: 700;
          margin-top: 16px;
          width: 480px;
          height: 56px;
          border-radius: 8px;
          border: none;
          margin-bottom: 115px;
        }
      }

      h1 {
        font-size: 64px;
        margin-top: 16px;
        font-weight: 600;
        width: 546px;

        span {
          display: block;
          color: $wash-yellow;
        }
      }
    }
  }

  &_section-two {
    display: block;
    padding-top: 80px;
    ._attributes {
      margin-bottom: 80px;
      h1 {
        text-align: center;
        margin-bottom: 40px;
      }
      &-body {
        display: flex;
        justify-content: space-between;
        &-item {
          display: flex;
          align-items: center;
          column-gap: 16px;

          .icon-wrapper {
            border-radius: 4px;
            background: #ffe6bc;
            padding: 16px;
            color: #323232;
            margin-bottom: 0;
          }

          .header {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 8px;
          }
          .info {
            color: #666;
            font-weight: 300;
            font-size: 14px;
            margin-bottom: 0;
          }
        }
      }
    }
    .benefits-flex {
      display: flex;
      column-gap: 24px;
      margin-bottom: 120px;

      .benefit {
        width: 100%;
        padding-bottom: 72px;

        &:first-child {
          background: #e6faff;
          img {
            top: 75px;
          }
        }

        &:last-child {
          background: #f6ffe3;

          p {
            margin-bottom: 24px;
          }

          img {
            top: 9.4rem;
          }
        }

        :nth-child(2) {
          margin-bottom: 32px;
        }
      }
    }
    .benefit {
      border-radius: 8px;
      background: #e4eeff;
      padding: 40px;
      padding-bottom: 104px;
      margin-bottom: 24px;
      position: relative;
      overflow: hidden;

      h1 {
        font-size: 32px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 24px;
      }

      img {
        position: absolute;
        right: 0;
        top: 12px;
      }

      li {
        margin-bottom: 15px !important;
        list-style: square;
      }

      p {
        line-height: 28px;
      }

      :nth-child(2) {
        margin-bottom: 32px;
      }
      :nth-child(3) {
        margin-bottom: 32px;
        width: 26em;
      }
      :nth-child(4) {
        width: 31em;
        margin-bottom: 0;
      }
    }

    ._services {
      h1 {
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        color: $wash-black;
      }

      .services-flex {
        display: flex;
        margin-top: 40px;
        column-gap: 24px;
        margin-bottom: 120px;

        .service {
          border-radius: 16px;
          background: #f5c87e;
          padding: 40px;
          color: $wash-black;
          width: 100%;

          ._text-section {
            min-height: 240px;
            height: auto;
          }

          img {
            width: 100px;
          }

          h2 {
            font-size: 32px;
            font-weight: 600;
            line-height: 150%;
            margin-top: 32px;
            margin-bottom: 24px;
          }

          p {
            line-height: 26px;
            margin-bottom: 32px;
            color: #323232;
            font-size: 16px;
            font-weight: 400;
          }

          button {
            margin-top: 40px;
            font-size: 14px;
            font-weight: 700;
            width: 100%;
            height: 56px;
            color: $wash-blue;
            border: none;
            letter-spacing: 0.28px;
            border-radius: 8px;
            background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
          }
        }
      }
    }

    ._pricing {
      h1 {
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        color: $wash-black;
        margin-bottom: 40px;
      }

      .pricing-flex {
        display: flex;
        column-gap: 24px;

        .pricing {
          color: $wash-black;
          border-radius: 16px;
          background: #fbe8ca;
          padding: 40px;
          width: 100%;

          h2 {
            font-size: 24px;
            font-weight: 600;
            line-height: 150%;
            margin-bottom: 24px;
          }

          ._price {
            color: #666;
            font-size: 40px;
            font-weight: 400;
            margin-bottom: 24px;
          }

          ._loads {
            margin-bottom: 24px;
            font-size: 16px;
            font-weight: 600;
          }

          ._benefits {
            height: 115px;

            p {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }

    ._extras {
      margin-top: 24px;
      background: #fbe8ca;
      border-radius: 16px;
      padding: 40px;
      h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 24px;
      }
      &-box {
        display: flex;
        justify-content: space-between;

        .extra {
          :first-child {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 8px;
          }
          :last-child {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 0;
          }
        }
      }
    }

    ._reviews {
      margin-top: 120px;
      color: $wash-black;
      padding-bottom: 120px;
      h1 {
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;

        margin-bottom: 40px;
      }
      .reviews-flex {
        display: flex;
        column-gap: 24px;
        .review-container {
          border-radius: 16px;
          background: #f4ede1;
          padding: 40px;
          font-size: 16px;

          .quotes-mark {
            margin-bottom: 16px;
          }

          p {
            font-weight: 400;
            line-height: 160%;
            margin-bottom: 50px;
          }

          .reviewer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .info {
              span {
                display: block;

                &:first-child {
                  font-weight: 600;
                }
              }
            }

            ._wrapper {
              height: 56px;
              width: 56px;
              border-radius: 50%;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  &_section-three {
    background: #020d1c;

    ._faqs {
      h1 {
        color: #fff;
        padding-top: 120px;
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 40px;
      }

      .accordion-button:not(.collapsed) {
        background: none;
      }

      .accordion-button:focus {
        box-shadow: none;
      }

      .accordion-item {
        margin-bottom: 8px;
        border-radius: 15px;
        padding-bottom: 15px;

        button {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-radius: inherit;
          height: 83px;
          color: $wash-black;
          font-size: 16px;
          font-weight: 600;
          box-shadow: none;
          margin-bottom: -15px;
        }

        .accordion-body {
          padding-top: 0;
        }

        &:last-of-type .accordion-button.collapsed {
          border-bottom-right-radius: none;
          border-bottom-left-radius: none;
        }
      }
    }

    ._current-location {
      margin-top: 100px;
      padding-bottom: 77px;
      color: white;
      h1 {
        text-align: center;
        margin-bottom: 24px;
      }

      div {
        display: flex;
        border-radius: 16px;
        border: 1px solid #1b2e40;
        background: #021024;
        justify-content: center;
        column-gap: 120px;
        height: 83px;
        align-items: center;
        p {
          color: white;
          font-weight: 600;
          font-size: 16px;
          margin: 0;
        }
      }
    }

    ._footer {
      border-top: 1px solid #041b3d;
      padding: 120px 90px;
      padding-bottom: 180px;

      &-elements {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        color: #fafafa;
        font-size: 14px;
        font-weight: 500;
        p {
          color: #fff;
          margin-bottom: 0;
        }
        img {
          width: 116px;
          height: 56px;
        }
        ul {
          list-style: none;
          display: flex;
          gap: 16px;
          color: #5d8ef9;
        }

        .logo-section {
          display: flex;
          align-items: baseline;
          column-gap: 26px;
        }

        p,
        a,
        ul {
          position: relative;
          top: -7px;
        }

        ul {
          top: 0;
        }

        a {
          cursor: pointer;
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
}

.schedule-pickup {
  &__header {
    background: #fff8ec;
    padding: 0px 120px;
    border-bottom: 8px solid #ffe6bc;
    margin-bottom: 64px;

    button {
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      background: #fff;
      color: $wash-black;
      font-size: 14px;
      font-weight: 600;
      width: 119px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .app-landing_section-one_header-container {
      padding-top: 10px;
    }

    .icons {
      flex: auto;
      justify-content: flex-end;
      li {
        background: #ffe6bc;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 18px;

        &:last-child {
          color: #17499f;
          font-size: 24px;
        }
      }
    }

    .extended-header {
      h3 {
        text-align: center;
        font-weight: 600;
        font-size: 64px;
        line-height: 77.5px;
        color: #020d1c;
        padding: 108px 0;
      }
    }
  }

  &__body {
    padding: 0 120px;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &__flow {
      &-tracker-wrapper {
        border-radius: 8px;
        background: #fafafa;
        display: flex;
        justify-content: center;
        gap: 121px;
        padding: 30px 0;
        margin-bottom: 40px;
        position: relative;

        &-mobile {
          display: none;
        }

        i {
          position: absolute;
          left: 30px;
          font-size: 20px;
          cursor: pointer;
        }
      }
      &-tracker {
        display: flex;
        align-items: center;
        gap: 16px;

        p {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
        }

        input {
          width: 24px;
          height: 24px;
        }

        &.disabled {
          p {
            color: #919191;
          }
        }
      }
    }

    &__steps-view {
      &-render {
        h2 {
          font-size: 36px;
          color: $wash-black;
          font-weight: 600;
        }
        p {
          color: #666;
          font-size: 16px;
          font-weight: 400;
        }

        .__options {
          display: flex;
          gap: 24px;
          cursor: pointer;
          .option {
            width: 100%;
            border-radius: 8px;
            border: 1px solid #d9d9d9;
            background: #fff;
            padding: 24px;

            &.active {
              border: 2px solid #efaf33;
            }

            .imgs {
              display: flex;
              justify-content: space-between;

              img {
                width: 24px;
                height: 24px;
              }

              .option-img {
                width: 56px;
                height: 56px;
              }
            }

            h3 {
              font-size: 20px;
              font-weight: 600;
              text-transform: capitalize;
              // margin-top: 24px;
              margin-bottom: 16px;
            }

            p {
              color: #666;
              font-size: 14px;
              font-weight: 300;
              line-height: 150%;
            }
          }
        }

        .input-group {
          input {
            border-left: 0;
            padding-left: 0;
          }
          .input-group-text {
            background: white;
          }
        }

        label {
          font-size: 14px;
          font-weight: 500;
        }
        select {
          font-size: 14px;
          font-weight: 600;
        }
        .etd {
          display: flex;
          border-radius: 8px;
          background: #d6e7ff;
          padding: 16px;
          gap: 8px;
          align-items: center;
          i {
            font-size: 24px;
          }
          p {
            margin-bottom: 0;
            color: $wash-black;
            font-size: 14px;
            font-weight: 300;

            b {
              font-weight: 600;
            }
          }
        }

        textarea {
          height: 120px;
          &::placeholder {
            color: #d9d9d9;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .col-summary {
        display: flex;
        justify-content: flex-end;
        height: fit-content;
      }

      .next-button {
        border-radius: 8px;
        border: none;
        background: #17499f;
        color: #fafafa;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        padding: 19px;
      }

      .next-button:disabled {
        background: #cfcfcf;
        cursor: not-allowed;
      }
    }

    &__steps-view-render.customize-wash {
      .option {
        width: 100%;

        .wash-counter-flex {
          display: flex;
          justify-content: space-between;
          align-items: baseline;

          h3 {
            color: #666;
            font-weight: 300;

            span {
              font-size: 14px;
              font-weight: 300;
              line-height: 150%;
            }
          }
          .wash-counter {
            display: flex;
            font-size: 24px;
            align-items: center;
            border-radius: 24px;
            background: #fafafa;
            width: fit-content;
            height: fit-content;
            gap: 40px;

            p {
              margin-bottom: 0;
              font-size: 20px;
            }

            .sign {
              height: 50px;
              display: flex;
              border-radius: 50%;
              justify-content: center;
              align-items: center;
              width: 50px;

              &._add {
                background: #ffd59a;
                border: none;
              }
              &._minus {
                background: #fff8ec;
                border: 1px solid #ffe6bc;
              }
            }
          }
        }
      }

      .wash-description {
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 16px;
        padding-top: 16px;
        width: 100%;
        margin-bottom: 24px;
        color: #17499f;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        i {
          margin-left: 4px;
          color: #17499f;
        }
      }

      h2 {
        color: #020d1c;
        font-size: 24px;
        font-weight: 500;
      }
      p {
        color: #666;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 24px;
      }
      .extras {
        .extra {
          display: flex;
          justify-content: space-between;
          border-radius: 8px;
          border: 1px solid #d9d9d9;
          padding: 16px;
          margin-bottom: 8px;

          .-info {
            p {
              margin-bottom: 0;
            }
            ._name {
              color: #020d1c;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 8px;
            }
            ._price {
              color: #666;
              font-size: 16px;
              font-weight: 400;
            }
          }

          .wash-counter {
            gap: 24px;
          }
        }
      }
    }

    &__steps-view-render.contact-details {
      .react-tel-input {
        .form-control {
          height: 56px;
          font-size: 1rem;
          width: -webkit-fill-available;
        }
      }

      .payments {
        h3 {
          font-size: 24px;
          font-weight: 400;
        }

        p {
          color: #666;
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 24px;
        }

        .payment-option {
          display: flex;
          border-radius: 8px;
          border: 1px solid #d9d9d9;
          padding: 26px 24px;
          align-items: center;
          gap: 32px;
          cursor: pointer;
          // margin-bottom: 16px;
          position: relative;
          &.active {
            border: 1px solid #f6ae2d;
          }
          &.disabled {
            cursor: not-allowed;
            background: #f5f5f5;
            color: #c4c4c4;

            p {
              color: #c4c4c4;
            }
          }
          input[type="radio"] {
            width: 20px;
            position: absolute;
            right: 30px;
          }
          input[type="radio"]:checked {
            accent-color: #f6ae2d;
            border: white;
          }

          i {
            font-size: 22px;
          }

          p {
            margin-bottom: 0;
            font-size: 16px;
            color: #020d1c;

            b {
              font-weight: 600;
            }
          }
          img {
            width: 30px;
            margin-right: -10px;
          }
        }
        .coupon {
          display: flex;
          gap: 16px;

          button {
            border-radius: 8px;
            border: 1px solid #020d1c;
            background: #fff;
            width: 120px;
            font-size: 14px;
            color: #020d1c;
            font-weight: 500;
          }
        }
      }
    }

    &__steps-view-render.complete-schedule-screen {
      text-align: center;
      margin-top: 100px;

      h2 {
        color: #020d1c;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      p {
        color: #666;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        width: 390px;
        margin-bottom: 24px;
      }

      .btn_link {
        width: 100%;
        color: #17499f;
        font-size: 15px;
        cursor: pointer;
      }

      button {
        border-radius: 8px;
        background: #17499f;
        border: none;
        width: 148px;
        height: 56px;
        color: #fafafa;
        font-size: 14px;
        font-weight: 700;
      }
    }

    &__steps-summary {
      border-radius: 8px;
      background: #fafafa;
      padding: 24px;
      width: 480px;

      &_mobile {
        display: none;
      }

      h3 {
        font-size: 24px;
        font-weight: 600;
        color: #020d1c;
        margin-bottom: 24px;
      }

      .address-block {
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 16px;
        margin-bottom: 16px;
        p {
          margin-bottom: 12px;
          color: #666;
          font-size: 16px;
          font-weight: 400;
        }
        b {
          color: #020d1c;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .billing-items {
        .item {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          font-weight: 500;

          span {
            color: #666;
            font-weight: 400;
          }
        }
      }

      .contact-details {
        border-top: 1px solid #d9d9d9;
        padding-top: 16px;
        margin-top: 16px;
      }

      .total {
        border-radius: 8px;
        background: #d6e7ff;
        display: flex;
        padding: 24px 16px;
        justify-content: space-between;
        font-size: 24px;
        color: #020d1c;
        margin-top: 40px;

        span {
          font-weight: 400;
        }
        b {
          font-weight: 600;
        }
      }
    }

    &__steps-tracker {
      position: relative;
      &_mobile {
        display: none;
      }
      border-radius: 8px;
      background: #fafafa;
      padding: 24px;
      width: 85%;

      h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .sub-text {
        color: #666;
        font-size: 14px;
        font-weight: 300;
        line-height: 150%;
        margin-bottom: 24px;
      }

      .tracker-wrapper {
        background: white;
        padding: 24px;
        border-radius: 24px;
        .step {
          margin-left: 45px;
          position: relative;
          h4 {
            font-size: 16px;
            font-weight: 700;

            &.done {
              &::before {
                background: #f6ae2d;
              }
            }
            &.processing {
              &::before {
                background: #ffe6bc;
              }
            }

            &::before {
              content: "";
              width: 20px;
              height: 20px;
              background: #ece9e9;
              position: absolute;
              border-radius: 21px;
              left: -37px;
              margin-top: 2px;
            }
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: #666;

            &.placeholder-text {
              color: #dbdada;
            }
          }

          &::before {
            content: "";
            left: -30px;
            height: 100%;
            width: 6px;
            background: #ebebeb;
            position: absolute;
            left: -30px;
            margin-top: 20px;
          }

          &:last-child {
            &::before {
              content: none;
            }
          }
        }
      }

      .login-btn {
        margin-top: 24px;
        border-radius: 8px;
        border: none;
        background: #17499f;
        width: 100%;
        padding: 20px 0;
        color: white;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}

.login .form {
  p {
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 40px;
  }

  label {
    color: #020d1c;
    font-size: 14px;
    font-weight: 500;
  }

  input {
    // height: 56px;

    // margin-bottom: 32px;
    ::placeholder {
      color: #d9d9d9;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .react-tel-input .form-control {
    width: fit-content;
  }

  button {
    border-radius: 8px;
    background: #17499f;
    width: 100%;
    padding: 20px 0;
    border: none;
    color: #fafafa;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .no-account {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;

    a {
      color: #17499f;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        color: #17499f;
        cursor: pointer;
      }
    }
  }
}

.__dashboard {
  .search-wrapper {
    padding: 16px 24px;
    border-radius: 8px;
    background: #fafafa;
    margin-top: -14px;

    input {
      height: 40px;
    }
  }
  .user-details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.-admin {
      margin-top: 16px;

      .dropdown-menu {
        transform: translate3d(-264px, 45px, 0px) !important;
        width: 304px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        border: none;
        padding: 16px 0;

        li {
          padding: 0 16px;
          padding-bottom: 10px;
          padding-top: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          a {
            font-size: 14px;
            color: #020d1c;
            font-weight: 500;
            padding: 0;
            margin: 0;
            &:hover {
              background: inherit;
            }
          }
          &:last-child {
            padding-bottom: 8px;
          }
          &:hover {
            background: #f8f9fa;
          }
        }
      }
    }

    .name_ {
      h2 {
        font-size: 24px;
        font-weight: 300;
      }
      b {
        font-weight: 600;
      }
      p {
        color: #666;
        font-size: 13px;
        font-weight: 300;
      }
    }
    .icon-user {
      background: #ffd59a;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      i {
        font-size: 24px;
        color: #17499f;

        &.dropdown {
        }
      }

      .user-card {
        box-shadow: 0px 2px 8px 0px #00000014;
        background: #fff;
        width: 304px;
        height: 128px;
        position: absolute;
        z-index: 1;
        top: 56px;
        right: 0;
        border-radius: 8px;
        padding: 16px;
        color: #020d1c;

        h3 {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 9px;
        }

        p {
          color: #666666;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          margin-bottom: 9px;
          display: flex;
          column-gap: 8px;
          align-items: center;
        }
      }
    }
  }
  .request-wash {
    background: #17499f;
    padding: 24px;
    border-radius: 8px;
    position: relative;
    h3 {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
    }
    p {
      color: #bce5fd;
      font-size: 13px;
      margin-bottom: 16px;
      font-weight: 300;
    }
    a {
      color: #ffbe1b;
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
    }
    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .requests-boards {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    gap: 24px;
    border-bottom: 1px solid #d9d9d9;
    &.no-btm-border {
      border-bottom: none;
      padding-bottom: 0;
    }
    .board {
      &:last-child {
        background: #fff;
        border: 1px solid #d9d9d9;
      }
      padding: 24px;
      border-radius: 8px;
      background: #ffbdb0;
      width: 100%;
      cursor: pointer;

      &.green {
        background: #cae99a;
      }
      &.blue {
        background: #d6e7ff;
      }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        i {
          font-size: 11px;
        }

        h6 {
          color: #020d1c;
          font-size: 14px;
          font-weight: 500;
        }
      }
      h3 {
        color: #020d1c;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
  .list-items {
    margin-top: 26px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      &-details {
        display: flex;
        justify-content: space-between;
        // align-items: baseline;
        gap: 16px;

        .icon-wrapper {
          width: 40px;
          height: 40px;
          background: #ffd59a;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          padding: 8px;

          i {
            font-size: 24px;
            color: #17499f;
          }
        }

        .details {
          h3 {
            color: #020d1c;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 6px;
          }
          p {
            color: #666;
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .help-container {
    margin-top: 24px;
    display: flex;
    gap: 24px;

    a {
      text-decoration: none;
    }

    h4 {
      color: #020d1c;
    }

    .help {
      padding: 24px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      cursor: pointer;

      img {
        margin-bottom: 16px;
      }

      p {
        margin-bottom: 0;
        color: #666;
        font-size: 13px;
        font-weight: 300;
        line-height: 150%;
      }
    }

    .modal-header {
      h3 {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    ._section {
      display: flex;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 12px;
      column-gap: 8px;
      margin-bottom: 10px;
      cursor: pointer;

      i {
        font-size: 25px;
        color: #666;
      }

      h4 {
        font-size: 15px;
        margin-bottom: 3px;
        font-weight: 600;
      }
      h6 {
        margin-bottom: 0;
        color: gray;
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
  ._requests {
    margin-top: 16px;
    ._back {
      font-size: 24px;
      cursor: pointer;
    }
    h3 {
      margin-top: 24px;
      color: #020d1c;
      font-size: 16px;
      font-weight: 600;
    }
    h6 {
      color: #666;
      font-size: 13px;
      font-weight: 400;
    }
    .menu-container-bg {
      border-radius: 8px;
      background: #edf3fc;
      display: flex;
      justify-content: space-between;
      padding: 4px;
      gap: 8px;
      margin-top: 16px;

      li {
        &.active {
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
          color: #020d1c;
        }
        list-style-type: none;
        color: #666;
        height: 48px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .details-item-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      h3 {
        font-size: 24px;
        font-weight: 500;
      }
    }
    .details-item {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      margin-bottom: 24px;
      flex-wrap: wrap;
      gap: 8px;
      span {
        font-weight: 600;
        color: #020d1c;
      }
      p {
        color: #666;
        font-weight: 400;
        margin-bottom: 0;
        text-transform: capitalize;
      }
    }

    .additional-order-container {
      background: #fafafa;
      padding: 16px;
      border-radius: 8px;
      margin-bottom: 16px;
      .header {
        display: flex;
        align-items: baseline;
        gap: 16px;
        margin-bottom: 16px;
        h5 {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: #020d1c;
          text-align: end;
          margin-bottom: 0;
        }
        span {
          border-radius: 32px;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
        }
      }
      .body {
        section {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          ._section {
            h3 {
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 8px;
              color: #020d1c;
              margin-top: 0;
            }
            p {
              color: #666666;
              font-size: 13px;
              font-weight: 400;
              margin-bottom: 0;
              text-transform: capitalize;
            }
            &:last-child {
              text-align: right;
              width: 60%;
            }
          }
        }
      }
    }

    .tracking {
      position: relative;
      padding-top: 16px;
      h4 {
        font-size: 16px;
        font-weight: 600;
      }

      .tracker-wrapper {
        background: white;
        padding: 28px 16px;
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        margin-bottom: 20px;

        background: #fff;
        .step {
          margin-left: 45px;
          position: relative;
          h4 {
            font-size: 16px;
            font-weight: 700;

            &.done {
              &::before {
                background: #f6ae2d;
                content: "✓";
              }
            }
            &.processing {
              &::before {
                background: #ffe6bc;
              }
            }

            &::before {
              content: "●";
              width: 20px;
              height: 20px;
              background: #ece9e9;
              position: absolute;
              border-radius: 21px;
              left: -37px;
              margin-top: 1px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              color: #fff;
            }
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: #666;

            span {
              width: 80%;
              display: block;
            }

            &.time {
              position: absolute;
              right: 24px;
              margin-top: -50px;
              color: #020d1c;
              font-weight: 600;
            }
          }

          &::before {
            content: "";
            // height: 47px;
            height: 100%;
            width: 6px;
            background: #ebebeb;
            position: absolute;
            left: -30px;
            margin-top: 20px;
          }

          &.done {
            &::before {
              background: #f6ae2d;
            }

            &.last {
              &::before {
                background: #ebebeb;
              }

              h4 {
                &.done {
                  &::before {
                    // animation: ripple 1s infinite;
                  }
                  &::after {
                    content: "";
                    position: absolute; /* Position absolutely */
                    width: 29px;
                    height: 29px;
                    border-radius: 50%; /* Make it a circle */
                    background-color: #f6ae2d;
                    left: -41px;
                    top: -3px;
                    transform: translate(-50%, -50%) scale(1);
                    animation: ripple 1s infinite; /* Apply the ripple animation */
                  }
                }
              }
            }
          }

          /* Define keyframes for the ripple animation */
          @keyframes ripple {
            0% {
              transform: scale(0.5); /* Start at half size */
              opacity: 0.7; /* Initial opacity */
            }
            100% {
              transform: scale(2.5); /* Scale up to 2.5 times original size */
              opacity: 0; /* Fade out */
            }
          }

          &:last-child {
            &::before {
              content: none;
            }
            p {
              margin-bottom: 0;

              &.time {
                margin-top: -30px;
              }
            }
          }
        }
      }
    }
  }
  .admin-tabs {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-left: 0;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 6px;
    margin-top: 36px;

    li {
      color: #666;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;

      &.active {
        color: #17499f;

        &::after {
          content: " ";
          border-radius: 8px 8px 0px 0px;
          background: #17499f;
          width: 80px;
          height: 5px;
          display: block;
          position: relative;
          top: 6px;
        }
      }
    }
  }
  .admin-tabs-content {
    position: relative;
    .column-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.brd-btm {
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 21px;
      }

      .title {
        h3 {
          color: #020d1c;
          font-size: 16px;
          font-weight: 600;
        }
        p {
          margin-bottom: 0;
          color: #666;
          font-size: 13px;
        }
      }
      .filter {
        background-color: #fafafa;
        border: 1px solid #edeff2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        cursor: pointer;

        i {
          font-size: 24px;
        }
      }
    }

    .filter-list-view {
      display: flex;
      gap: 8px;
      margin-top: 24px;

      li {
        list-style: none;
        border-radius: 32px;
        border: 1px solid #17499f;
        background: #17499f;
        padding: 8px 12px;
        text-transform: capitalize;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .admin-content-list {
      margin-top: 24px;
      .item {
        padding: 16px 0;
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        cursor: pointer;

        .time-info {
          display: flex;
          justify-content: space-between;

          p {
            margin-bottom: 0;
            display: flex;
            gap: 24px;
            color: #020d1c;
            font-size: 16px;
            font-weight: 500;

            span {
              color: #666;
              font-size: 14px;
              font-weight: 600;
            }
            i {
              cursor: pointer;
            }
          }
        }
        .item-props {
          display: flex;
          margin-top: 16px;
          column-gap: 24px;

          p {
            display: flex;
            gap: 4px;
            align-items: center;
            margin-bottom: 0;
            i {
              color: #666666;
              font-size: 12px;
            }
            span {
              font-size: 13px;
              color: #666;
            }
          }
        }
      }

      .request-item {
        display: flex;
      }
    }

    .pagination-container {
      display: flex;
      margin-top: 16px;
      align-items: baseline;
      gap: 12px;

      select {
        width: 95px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #edeff2;
      }

      .pagination-numbers {
        list-style: none;
        display: flex;
        gap: 4px;
        position: absolute;
        right: 0;

        li {
          border: 1px solid #edeff2;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;

          a {
            text-decoration: none;
            color: #170f2e;
          }

          &.selected {
            background: #17499f;

            a {
              color: white;
            }
          }
        }
      }
    }

    .filter-modal {
      .modal-body {
        padding: 24px 32px !important;
      }
      .modal-footer {
        padding: 8px 32px;
      }
      button.apply-btn {
        background-color: #17499f;
        min-width: 156px;
        height: 56px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .modal-content {
      .modal-title {
        font-weight: 600;
      }
      .modal-footer {
        border: none;
        justify-content: space-between;
        margin-bottom: 24px;

        p {
          color: #17499f;
          font-size: 14px;
          margin-bottom: 0;
          font-weight: 700;
          cursor: pointer;
        }

        button.modal-button {
          border-radius: 8px;
          background: #17499f;
          width: 161px;
          height: 56px;
          color: #fafafa;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .modal-body {
        border-color: #d9d9d9;
        padding: 0;

        .filter-container {
          margin-bottom: 24px;
          h3 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 16px;
          }
          .filter-list {
            display: flex;
            list-style: none;
            gap: 8px;

            li {
              font-size: 12px;
              border-radius: 32px;
              border: 1px solid #d9d9d9;
              background: #f4f4f4;
              padding: 8px 12px;
              font-weight: 500;
              color: #666;
              cursor: pointer;

              &.active {
                border: 1px solid #17499f;
                background: #17499f;
                color: #fff;
              }
            }
          }
          .range-inputs {
            display: flex;
            gap: 24px;
            input {
              width: 120px;
              height: 49px;
            }
          }
        }
        .status-type-container {
          padding: 30px 46px;
          .status {
            display: flex;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #d9d9d9;
            padding: 16px;
            justify-content: space-between;
            margin-bottom: 8px;
            cursor: pointer;

            p {
              margin-bottom: 0;
              color: #919191;
              font-size: 14px;
              font-weight: 500;
            }

            input {
              height: 18px;
              width: 18px;
              outline: auto;
              outline-color: #d0d1d1;
            }

            &.not-disabled {
              p {
                color: #020d1c;
              }

              input {
                outline-color: #020d1c;
              }
            }
          }
          button.modal-button {
            margin-top: 24px;
            border-radius: 8px;
            background: #17499f;
            height: 56px;
            border: none;
            width: 288px;
            color: #fafafa;
            font-size: 14px;
            font-weight: 700;
          }
        }
        .update-wash-container {
          padding: 24px 48px;
          ._header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: 1px solid #d9d9d9;
            padding-bottom: 24px;
            h4 {
              font-size: 20px;
              font-weight: 600;
              line-height: 24px;
              color: #020d1c;
              margin-bottom: 24px;
              text-transform: capitalize;
            }
            h3 {
              color: #666666;
              font-size: 24px;
              font-weight: 300;
              line-height: 29px;

              span {
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
          ._body {
            padding-top: 24px;

            h3 {
              font-size: 24px;
              font-weight: 500;
              line-height: 29px;
              margin-bottom: 24px;
            }

            .extra {
              display: flex;
              justify-content: space-between;
              border: 1px solid #d9d9d9;
              border-radius: 8px;
              padding: 16px;
              align-items: center;
              margin-bottom: 8px;
              .-info {
                ._name {
                  font-size: 14px;
                  color: #020d1c;
                  font-weight: 500;
                  line-height: 16px;
                  margin-bottom: 8px;
                }
                ._price {
                  color: #666666;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  margin-bottom: 0px;
                }
              }
            }

            .btn {
              background: #17499f;
              width: 288px;
              height: 56px;
              border-radius: 8px;
              font-size: 14px;
              font-weight: 700;
              line-height: 16px;
              color: white;
              margin: 32px 0;
            }
          }
        }
        .reschedule-wash-container {
          padding: 16px 56px;

          p {
            font-weight: 600;
            font-size: 14px;
            line-height: 16.94px;
            margin-bottom: 24px;
            background: #d6e7ff;
            padding: 16px;
            border-radius: 8px;
          }

          select {
            margin-bottom: 24px;
          }

          button {
            background: #17499f;
            border-radius: 8px;
            width: 288px;
            height: 56px;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 40px;
            margin-top: 8px;
          }
        }
        .add-complaint-container {
          padding: 37px 56px;

          textarea {
            display: block;
            width: 100%;
            height: 120px;
            border-radius: 8px;
            border: 1px solid #d9d9d9;
            margin-bottom: 24px;
            padding: 20px 16px;
            font-size: 14px;
            font-weight: 500;

            &:focus {
              outline: none;
              box-shadow: none;
            }

            &::placeholder {
              color: #d9d9d9;
            }
          }

          button {
            background: #17499f;
            border-radius: 8px;
            width: 288px;
            height: 56px;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 32px;
          }
        }
      }
    }
  }
  .form-modal {
    .modal-header {
      border-bottom-color: #d9d9d9;
    }
    .modal-body {
      padding: 56px;
      padding-top: 40px;

      button.modal-button {
        margin-top: 32px;
        border-radius: 8px;
        background: #17499f;
        width: 288px;
        height: 56px;
        color: #fafafa;
        font-size: 14px;
        font-weight: 700;
      }

      .react-date-picker-wrapper {
        .rmdp-container {
          display: block !important;
          input {
            width: 100%;
            height: 56px;
          }
        }
        .react-datepicker-wrapper {
          display: block;
        }
      }

      .btn_flex {
        display: flex;
        justify-content: space-between;
        gap: 24px;

        button {
          width: 100%;

          &.outline {
            background: #f4f4f4;
            color: #17499f;
            border: 1px solid #f4f4f4;
          }
        }
      }

      ._list_times {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        .rmdp-container {
          width: 100%;
          input {
            width: 100%;
            height: 56px;
          }
        }

        i {
          margin-top: 25px;
          display: block;
          cursor: pointer;
        }
      }

      &._prescheduled-modal-body {
        .add_schedule_btn {
          background: none;
          border: 0;
          width: fit-content;
          margin: 24px 0;
          font-weight: 700;
          color: #17499f;
          padding: 0;
        }

        ._create-schedule {
          background: #17499f;
          height: 56px;
          width: 288px;
          display: block;
        }

        ._pre-schedule-list {
          h3 {
            font-size: 16px;
            font-weight: 700;
            line-height: 19.36px;
            margin-bottom: 26px;
            margin-top: 4px;
          }
          .schedule_flex {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #d9d9d9;
            margin-bottom: 18px;

            &:last-child {
              margin-bottom: 32px;
            }

            div {
              h4 {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 16px;
              }
              .props {
                display: flex;
                gap: 40px;

                p {
                  font-size: 14px;
                  color: #666666;
                }
              }
              i {
                font-size: 24px;
                margin-bottom: 13px;
                cursor: pointer;
              }
              p {
                font-size: 14px;
                color: #666666;
              }
              &._cancel {
                text-align: right;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      border: none;
    }
  }
  .schedule-view {
    .goback_ {
      margin-bottom: 0;
      i {
        cursor: pointer;
        font-size: 26px;
      }
    }
    .schedule-view-header {
      display: flex;
      justify-content: space-between;
      padding: 11px;
      border-bottom: 1px solid #d9d9d9;

      button {
        border-radius: 8px;
        background: #17499f;
        height: 41px;
        border: none;
        color: #fafafa;
        font-size: 14px;
        font-weight: 700;
        padding: 12px 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }

      .details {
        h3 {
          color: #020d1c;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 8px;
        }
        .extras {
          display: flex;
          gap: 24px;
          p {
            margin-bottom: 0;
            color: #666666;
            display: flex;
            gap: 4px;
            align-items: center;
            i {
              font-size: 14px;
            }
            span {
              font-size: 13px;
            }
          }
        }
      }
    }
    .schedule-view-body {
      display: flex;
      justify-content: space-between;
      margin-top: 18px;
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
      cursor: pointer;

      ._left {
        ._title {
          display: flex;
          margin-bottom: 16px;

          h4 {
            color: #020d1c;
            font-size: 16px;
            font-weight: 500;
          }
        }
        ._extras {
          display: flex;
          gap: 4px;
          margin-bottom: 17px;

          p {
            height: 24px;
            border-radius: 4px;
            background: #f4f4f4;
            font-size: 13px;
            font-weight: 500;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #666;
            margin-bottom: 0;
            text-transform: capitalize;
          }
        }
        ._contact {
          display: flex;
          flex-flow: wrap;
          row-gap: 8px;
          column-gap: 24px;
          color: #666;

          p {
            display: flex;
            margin-bottom: 0;
            gap: 4px;
            align-items: center;

            i {
              font-size: 14px;
            }
            span {
              font-size: 13px;
              text-transform: capitalize;
            }
          }
        }
      }
      .date {
        display: flex;
        column-gap: 24px;

        i {
          cursor: pointer;
        }

        p {
          color: #666;
          font-size: 14px;
          font-weight: 600;
          position: absolute;
          width: 100%;
          margin-left: -75px;
        }

        .dropdown-menu {
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
          border: none;
          width: 250px;
          li {
            cursor: pointer;
            a {
              color: #020d1c;
              font-size: 14px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
    &.payments-view {
      .schedule-view-body {
        padding-bottom: 0;
      }
    }
  }
  .request-view {
    .goback_ {
      margin-bottom: 0;
      i {
        cursor: pointer;
        font-size: 26px;
      }
    }
    .price_ {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      margin-bottom: 40px;

      h2 {
        color: #020d1c;
        font-size: 24px;
        font-weight: 600;
      }
    }
    .items {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .item {
        h5 {
          color: #020d1c;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }
        h6 {
          color: #666;
          font-size: 13px;
          font-weight: 400;
          text-transform: capitalize;
        }

        &:last-child {
          text-align: right;
        }
      }

      &.hasBorderBottom {
        padding-bottom: 24px;
        border-bottom: 1px solid #d9d9d9;
      }
    }
    .additional-order-container {
      background: #fafafa;
      padding: 16px;
      border-radius: 8px;
      margin-bottom: 16px;
      .header {
        display: flex;
        align-items: baseline;
        gap: 16px;
        margin-bottom: 16px;
        h5 {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: #020d1c;
          text-align: end;
        }
        span {
          border-radius: 32px;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
        }
      }
      .body {
        section {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          ._section {
            h3 {
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 8px;
              color: #020d1c;
            }
            p {
              color: #666666;
              font-size: 13px;
              font-weight: 400;
              margin-bottom: 0;
              text-transform: capitalize;
            }
            &:last-child {
              text-align: right;
              width: 60%;
            }
          }
        }
      }
    }
    .actions {
      margin-top: 47px;
      &-btn {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        margin-bottom: 24px;

        &:last-child {
          button {
            background: #f4f4f4;
          }
        }

        button {
          border-radius: 8px;
          background: #d6e7ff;
          height: 56px;
          width: 100%;
          border: none;
          color: #17499f;
          font-size: 14px;
          font-weight: 700;

          &.update {
            background: #17499f;
            color: #fff;
          }
        }
      }
    }
  }
}

.empty-container {
  text-align: center;
  margin-top: 96px;
  img {
    margin-bottom: 16px;
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: #020d1c;
    margin-bottom: 8px;
  }
  p {
    width: 390px;
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }

  button {
    border-radius: 8px;
    background: #17499f;
    height: 56px;
    border: none;
    padding: 0px 24px;
    color: #fafafa;
    font-size: 14px;
    font-weight: 700;
  }
}

.wash-items-container {
  margin-top: 24px;
  .wash-item {
    cursor: pointer;
    padding-bottom: 16px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 16px;
    .wash-item-no {
      display: flex;
      justify-content: space-between;
    }
    .wash-item-props {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      .date {
        color: #666;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .wash-extras {
        display: flex;
        flex-flow: wrap;
        gap: 4px;
        width: 70%;
        p {
          margin-bottom: 0;
          border-radius: 4px;
          background: #f4f4f4;
          color: #666;
          font-size: 13px;
          font-weight: 500;
          display: flex;
          align-items: center;
          padding: 4px 8px;
          height: 24px;
          text-transform: capitalize;
        }
      }
    }
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 8px;

  h2 {
    font-size: 16px;
    margin-bottom: 0;
  }
  span {
    text-transform: capitalize;
    border-radius: 32px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 8px;
    height: 24px;

    &.successful,
    &.completed {
      color: #758e4f;
      background: #e4ffbb;
    }
    &.received,
    &.delivering {
      background: #edf3fc;
      color: #17499f;
    }
    &.pending,
    &.pickup,
    &.folding,
    &.washing,
    &.drying {
      background: #fff8ec;
      color: #f26419;
    }
  }
}

.terms-condition {
  .body {
    p {
      font-size: 18px;

      span {
        text-transform: uppercase;
        line-height: 32px;
      }
    }
    h3 {
      font-size: 26px;
      line-height: 48px;
      font-weight: 700;
      padding: 25px 0 0px 0;
      text-transform: uppercase;
    }
    h5 {
      font-size: 20px;
      line-height: 48px;
      font-weight: 700;
      padding: 25px 0 0px 0;
      text-transform: inherit;
    }
    li {
      list-style: auto;
      margin-bottom: 12px;

      a {
        font-weight: 600;
        text-decoration: none;
        color: #020d1c;
      }
    }
  }
}

.faqs {
  h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    color: #020d1c;
    margin-bottom: 8px;
  }

  h6 {
    color: #666666;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 32px;
  }

  .accordion-item {
    border: none;
    margin-bottom: 16px;
    .accordion-button {
      background: #fafafa;
      border-radius: 16px;
      color: #020d1c;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;

      &:focus {
        box-shadow: none;
        outline: none;
      }
      &:not(.collapsed) {
        box-shadow: none;
      }
    }
  }
}

.schedule-template {
  .header {
    text-align: center;
    background: #fff8ec;
    padding: 20px;
  }
  .body {
    margin-top: 20px;

    .schedule-title {
      font-size: 24px;
      color: #020d1c;
      line-height: 29px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .schedule-details {
      display: flex;
      justify-content: space-between;

      p {
        display: flex;
        gap: 4px;
        color: #666666;
        font-size: 13px;
        font-weight: 500;
        line-height: 15px;
        align-items: center;
      }
    }

    .schedule-requests-list {
      .request {
        border-top: 1px solid #d9d9d9;
        padding-top: 16px;
        margin-bottom: 16px;
        h3 {
          color: #020d1c;
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;

          span {
            background: #edf3fc;
            font-size: 12px;
            line-height: 14px;
            font-weight: 600;
            color: #17499f;
            text-transform: capitalize;
            padding: 4px 8px;
            border-radius: 32px;
            margin-left: 8px;
          }
        }
        .tags {
          display: flex;
          gap: 4px;
          margin-top: 16px;
          p {
            background: #f4f4f4;
            color: #666666;
            font-size: 13px;
            line-height: 15px;
            font-weight: 500;
            padding: 4px 8px;
            border-radius: 4px;
            text-transform: capitalize;
          }
        }
        .contact-information {
          display: flex;
          flex-flow: wrap;
          column-gap: 24px;
          p {
            color: #666666;
            font-size: 13px;
            font-weight: 500;
            line-height: 15px;
            display: flex;
            gap: 4px;
            align-items: center;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
